@use '@angular/material' as mat;

html,
body {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  font-family: 'Roboto';
}

.mat-tooltip.tooltip {
  font-size: 16px;
}
.transparent .mat-dialog-container {
  box-shadow: none;
  background: rgba(0, 0, 0, 0);
}
:root {
  --tile-style-no-text-in-input: rgb(255, 255, 255);
  --tile-style-text-set-in-input: rgb(255, 211, 146);
  --tile-style-set-by-default-this-turn: rgb(255, 255, 255);
  --tile-style-set-by-player1-this-turn: rgb(153, 253, 128);
  --tile-style-set-by-player2-this-turn: rgb(116, 253, 223);
  --tile-style-set-by-player3-this-turn: rgb(163, 169, 255);
  --tile-style-set-by-player4-this-turn: rgb(241, 138, 255);
  --tile-style-set-by-default: rgba(255, 255, 255, 0.8);
  --tile-style-set-by-player1: rgb(153, 253, 128, 0.7);
  --tile-style-set-by-player2: rgba(116, 253, 223, 0.7);
  --tile-style-set-by-player3: rgba(163, 169, 255, 0.7);
  --tile-style-set-by-player4: rgba(241, 138, 255, 0.7);
  --tile-style-char-wrong: rgb(255, 146, 146);
  --priority-match-color-low: yellow;
  --priority-match-color-medium: green;
  --priority-match-color-high: red;
  --priority-match-color-theme: black;
  --priority-match-color-background: black;
  --show-theme-priority-color: rgb(0, 0, 0, 0.8);
}
.sticky-to-top {
  position: sticky;
  top: 0px;
  z-index: 100;
}
.gap-ten-px {
  gap: 10px;
}
.flex-base {
  display: flex;
  box-sizing: border-box;
}
.flex-column {
  @extend .flex-base;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-orient: vertical;
}
.flex-row {
  @extend .flex-base;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-orient: horizontal;
}
.flex-stretch {
  flex: 1;
  box-sizing: border-box;
}
.stretch-max {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.stretch-min-max {
  box-sizing: border-box;
  min-width: 100%;
  min-height: 100%;
}
.flex-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-align-start-center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.align-center-end-row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mat-button-toggle-label-content {
  line-height: 42px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.padd-5-px {
  padding-left: 5px;
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}
.padd-left-right-5-px {
  padding-left: 5px;
  padding-right: 5px;
}
.padd-top-bottom-5-px {
  padding-top: 5px;
  padding-bottom: 5px;
}
.w-30 {
  max-width: 30px;
}
.w-50 {
  max-width: 50px;
}
.w-60 {
  max-width: 60px;
}
.w-70 {
  max-width: 70px;
}
.w-100 {
  max-width: 100px;
}
.w-150 {
  max-width: 150px;
}
textarea,
input.text,
input[type='text'],
input[type='button'],
input[type='submit'],
.input-checkbox {
  border-radius: 0 !important;
}
.stretch-badge {
  .mat-badge-content {
    width: auto !important;
    border-radius: 13px !important;
    right: unset !important;
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
}
.mat-expansion-panel {
  box-shadow: none !important;
  border: 1px 1px 1px 1px;
  border: rgb(0 0 0 / 26%);
  border-width: 1px;
  border-style: solid;
}
.mat-icon {
  vertical-align: middle;
}
span {
  font-family: 'Roboto';
}
mat-cell:first-of-type,
mat-header-cell:first-of-type,
mat-footer-cell:first-of-type {
  padding-left: 10px !important;
}

.my-small-form-field {
  @include mat.form-field-density(-2);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
  appearance: textfield;
}

.disable-text-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.showing {
  visibility: visible;
  display: block;
}
.hidden {
  visibility: hidden;
  display: none;
}
