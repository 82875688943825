@use '@angular/material' as mat;

@include mat.core();

:root {
  --dark-grey: #212121;
  --medium-grey: #757575;
  --medium-to-light-grey: #b0b0b0;
  --lighter-grey: #c7c7c7;
  --light-grey: #e0e0e0;
  --very-light-grey: #eeeeee;
  --selected-question-background: #b5c5fc;
  --selected-question-background-dark: #90a9fa;
}

$dark-grey-palette: mat.m2-define-palette(mat.$m2-grey-palette, 900, 600, 900);
$medium-grey-palette: mat.m2-define-palette(mat.$m2-grey-palette, 600, 300, 600);

$krysskampen-primary: $dark-grey-palette;
$krysskampen-accent: $medium-grey-palette;

$krysskampen-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $krysskampen-primary,
      accent: $krysskampen-accent,
    ),
    typography: mat.m2-define-typography-config(),
  )
);

@include mat.all-component-themes($krysskampen-theme);
